import {
  createVuesticEssential,
  VaDropdownPlugin,
  VaToastPlugin,
  VaModalPlugin,
  ColorsClassesPlugin,
  BreakpointConfigPlugin
} from "vuestic-ui";
import { markRaw, computed, watch } from "vue";
import { useHead, defineNuxtPlugin, useCookie } from "#imports";
import NuxtLink from "#app/components/nuxt-link";
import configFromFile from "#vuestic-config";
function getGlobalProperty(app, key) {
  return app.config.globalProperties[key];
}
export default defineNuxtPlugin(async (nuxtApp) => {
  const { vueApp: app } = nuxtApp;
  const moduleOptions = JSON.parse(`{"config":{"colors":{"variables":{"primary":"#575757"}}},"css":["grid","typography","reset","smart-helpers","typography"],"fonts":false,"themeCookieKey":"vuestic-theme"}`);
  const themeCookie = useCookie(moduleOptions.themeCookieKey);
  const userConfig = configFromFile || moduleOptions.config || {};
  const configWithColors = {
    ...userConfig,
    colors: {
      currentPresetName: themeCookie.value || userConfig.colors?.currentPresetName || "light",
      ...userConfig.colors
    }
  };
  app.use(createVuesticEssential({
    config: { ...configWithColors, routerComponent: markRaw(NuxtLink) },
    // TODO: Would be nice to tree-shake plugins, but they're small so we can let it be for now.
    // Should be synced with create-vuestic.ts
    plugins: {
      BreakpointConfigPlugin,
      VaDropdownPlugin,
      VaToastPlugin,
      VaModalPlugin,
      ColorsClassesPlugin
    },
    /** Do not import any components. Nuxt will import them automatically */
    components: {}
  }));
  const colorConfig = getGlobalProperty(app, "$vaColorConfig");
  if (colorConfig) {
    useHead(computed(() => {
      return {
        style: [
          {
            innerHTML: colorConfig.renderCSSVariablesStyleContent()
          }
        ]
      };
    }));
  }
  const colorsClasses = getGlobalProperty(app, "$vaColorsClasses");
  if (colorsClasses) {
    useHead(computed(() => {
      return {
        style: [
          {
            innerHTML: colorsClasses.renderColorHelpers()
          }
        ]
      };
    }));
  }
  const { globalConfig } = getGlobalProperty(app, "$vaConfig");
  watch(() => globalConfig.value.colors.currentPresetName, (newTheme) => {
    themeCookie.value = newTheme;
  }, { immediate: true });
});
