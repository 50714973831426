import {createI18n} from 'vue-i18n'
import nl from '../locales/nl.json'

export default defineNuxtPlugin(({vueApp}) => {
    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale: 'nl',
        messages: {
            nl
        }
    })

    vueApp.use(i18n)
})
