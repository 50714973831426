import { defineStore } from 'pinia'
import { routes } from '~/config/constants'

const initialState = 0
export const useNavigationStore = defineStore('navigation', () => {
    // State
    const step = ref(initialState)
    const params = ref(null)

    const total = routes.length

    // Actions
    const nextStep = async () => {
      step.value++
      await navigateTo(routes[step.value].url)
    }

    const previousStep = async () => {
      step.value--
      await navigateTo(routes[step.value].url)
    }

    const goToStep = async (id) => {
      step.value = id
      await navigateTo(routes[step.value].url)
    }
    const clear = () => {
      step.value = initialState
    }

    return {
      step,
      total,
      params,
      nextStep,
      previousStep,
      goToStep,
      clear
    }
  },
  {
    persist: true,
  })
