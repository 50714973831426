import VueHotjar from "vue-hotjar-next";

export default defineNuxtPlugin((nuxtApp) => {
    const { hotjarEnabled, hotjarId } = useRuntimeConfig().public

    nuxtApp.vueApp.use(VueHotjar, {
        id: hotjarId,
        isProduction: hotjarEnabled,
        snippetVersion: 6
    });
});
