import { default as arrangementfkUICFvnurMeta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/arrangement.vue?macro=true";
import { default as bedanktv2Hr61frMyMeta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/bedankt.vue?macro=true";
import { default as datumHDas66vX19Meta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/datum.vue?macro=true";
import { default as donerenAADNH72K8WMeta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/doneren.vue?macro=true";
import { default as indexBkBgGl6vtWMeta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/index.vue?macro=true";
import { default as overzichtqN5uYVYNLLMeta } from "/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/overzicht.vue?macro=true";
export default [
  {
    name: arrangementfkUICFvnurMeta?.name ?? "arrangement",
    path: arrangementfkUICFvnurMeta?.path ?? "/arrangement",
    meta: arrangementfkUICFvnurMeta || {},
    alias: arrangementfkUICFvnurMeta?.alias || [],
    redirect: arrangementfkUICFvnurMeta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/arrangement.vue").then(m => m.default || m)
  },
  {
    name: bedanktv2Hr61frMyMeta?.name ?? "bedankt",
    path: bedanktv2Hr61frMyMeta?.path ?? "/bedankt",
    meta: bedanktv2Hr61frMyMeta || {},
    alias: bedanktv2Hr61frMyMeta?.alias || [],
    redirect: bedanktv2Hr61frMyMeta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: datumHDas66vX19Meta?.name ?? "datum",
    path: datumHDas66vX19Meta?.path ?? "/datum",
    meta: datumHDas66vX19Meta || {},
    alias: datumHDas66vX19Meta?.alias || [],
    redirect: datumHDas66vX19Meta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/datum.vue").then(m => m.default || m)
  },
  {
    name: donerenAADNH72K8WMeta?.name ?? "doneren",
    path: donerenAADNH72K8WMeta?.path ?? "/doneren",
    meta: donerenAADNH72K8WMeta || {},
    alias: donerenAADNH72K8WMeta?.alias || [],
    redirect: donerenAADNH72K8WMeta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/doneren.vue").then(m => m.default || m)
  },
  {
    name: indexBkBgGl6vtWMeta?.name ?? "index",
    path: indexBkBgGl6vtWMeta?.path ?? "/",
    meta: indexBkBgGl6vtWMeta || {},
    alias: indexBkBgGl6vtWMeta?.alias || [],
    redirect: indexBkBgGl6vtWMeta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: overzichtqN5uYVYNLLMeta?.name ?? "overzicht",
    path: overzichtqN5uYVYNLLMeta?.path ?? "/overzicht",
    meta: overzichtqN5uYVYNLLMeta || {},
    alias: overzichtqN5uYVYNLLMeta?.alias || [],
    redirect: overzichtqN5uYVYNLLMeta?.redirect || undefined,
    component: () => import("/home/tickets-hzbmt/tickets.muzieum.nl/client/pages/overzicht.vue").then(m => m.default || m)
  }
]