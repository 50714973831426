export const apiClient = (url, options) => {
  const instance = $fetch.create({
    baseURL: useRuntimeConfig().public.ovaticApi,
    withCredentials: true,
    async onRequest({ request, options }) {
      // Set the request headers
      options.headers = options.headers || {};
      options.headers['Accept'] = 'application/json'
    },
    async onRequestError({ request, options, error }) {

    },
    async onResponse({ request, response, options }) {
      return response._data;
    },
    async onResponseError({ request, response, options }) {
      return response._data
    }
  })
  return instance(url, options)
}
