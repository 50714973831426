import { defineStore } from 'pinia'

const initialState =
    {
        ticketChoice: {
            date: null,
            activity: []
        },
        extra: [],
        donation: 0,
        paymentMethod: null,
        acceptedTerms: false,
        totalOrderAmount: 0
    }
export const useGlobalStore = defineStore('global', () => {
        // State
        const formData = ref(initialState)

        // Actions
        const update = (payload) => {
            formData.value = payload
        }
        const clear = () => {
            formData.value = initialState
        }

        const resetField = (field) => {
            formData.value[field] = initialState[field]
        }

        return {
            formData,
            update,
            clear,
            resetField
        }
    },
    {
      persist: {
        storage: persistedState.sessionStorage,
      },
    })
