import plugin_vue3_A0OWXRrUgq from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/tickets-hzbmt/tickets.muzieum.nl/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_FRmGFsEaPh from "/home/tickets-hzbmt/tickets.muzieum.nl/.nuxt/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_1UohGbtF8v from "/home/tickets-hzbmt/tickets.muzieum.nl/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import hotjar_7TboiBQmQ7 from "/home/tickets-hzbmt/tickets.muzieum.nl/client/plugins/hotjar.ts";
import i18n_mDfVcd1qKW from "/home/tickets-hzbmt/tickets.muzieum.nl/client/plugins/i18n.ts";
import vuedatepicker_B21stATLIk from "/home/tickets-hzbmt/tickets.muzieum.nl/client/plugins/vuedatepicker.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_client_LcKgStRyi6,
  plugin_FRmGFsEaPh,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  plugin_1UohGbtF8v,
  hotjar_7TboiBQmQ7,
  i18n_mDfVcd1qKW,
  vuedatepicker_B21stATLIk
]