export const routes = [
    { label: 'Met wie kom je?', url: '/' },
    { label: 'Datum en tijd', url: '/datum' },
    { label: 'Arrangement', url: '/arrangement' },
    { label: 'Donatie', url: '/doneren' },
    { label: 'Gegevens', url: '/overzicht' },
    { label: 'Bedankt', url: '/bedankt' },
]

export const config = {
    allowedParams: ['adults', 'kids', 'date', 'tid'], // List of allowed url parameters that can be used to pre-select data
    donationAmounts: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: 'Anders, namelijk:', value: 'custom'},
    ],
    genderOptions: [
        { text: 'Mevrouw', value: 'Female' },
        { text: 'De heer', value: 'Male' },
        { text: 'Geen aanhef', value: 'Unknown' },
    ]
}
