import {useNavigationStore} from '~/stores/navigationStore'
import {useTicketStore} from '~/stores/ticketStore'
import {config, routes} from '~/config/constants'
import moment from 'moment'

/**
 * Navigation middleware that runs globally.
 * Prevents users from visiting steps directly or ending up on the wrong step when user the browsers back button
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
    const { priceTypeIds } = useRuntimeConfig().public
    const store = useNavigationStore()
    const ticketStore = useTicketStore()
    const {step, params} = storeToRefs(store)
    const {tickets} = storeToRefs(ticketStore)

    // Stay on last page in routes array (/bedankt)
    if (to.path === routes[routes.length - 1].url && to.query?.tid?.length > 0) {
        step.value = 5;
        return;
    }

    // Stay on een na last page in routes array (/bedankt)
    if (to.path === routes[routes.length - 2].url && to.query?.tid?.length > 0) {
        step.value = 4;
        return;
    }

    //Check for allowed parameters to bypass first step
    if (Object.keys(to.query).length > 0) {
        const filteredParams = Object.entries(to.query).filter(param => {
            return config.allowedParams.includes(param[0])
        })

        if (filteredParams.length === 0) {
            step.value = 0
            return navigateTo('/')
        }
        if (to.query.adults || to.query.kids) {
            await ticketStore.getAll()
            tickets.value.map((ticket, index) => {
                if (ticket.pricetypeID === priceTypeIds[0]) {
                    tickets.value[index].numberOfTickets = to.query.adults
                }
                if (ticket.pricetypeID === priceTypeIds[1]) {
                    tickets.value[index].numberOfTickets = to.query.kids
                }
            })
        }

        step.value = 1
        if (moment(to.query.date, 'YYYY-MM-DD', true).isValid()) {
            params.value = to.query
        }

        return navigateTo(routes[step.value].url)
    }

    //Catch browsers back button behaviour
    if (from.path !== routes[0].url && from.path === routes[step.value].url) {
        step.value = useArrayFindIndex(routes, route => route.url === to.path).value
    }

    if (to.path === routes[0].url) {
        step.value = 0;
    }

    if (to.path !== routes[0].url && to.path !== routes[step.value].url) {
        return navigateTo('/')
    }
})
