<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import markerSDK from '@marker.io/browser';

const config = useRuntimeConfig()
if (config.public.markerIoEnabled && config.public.markerIoProject) {
    const widget = await markerSDK.loadWidget({
        project: config.public.markerIoProject,
    });
}
</script>

<style lang="scss">

</style>
