import { defineStore } from 'pinia'
import { apiClient } from '~/composables/apiClient'

export const useCrmStore = defineStore('crm', () => {
        // State
        const loading = ref(false)
        const customer = reactive({ id: 0, type: 'contact', contactdata: {}, phone: [{phoneNumber: null}], address: {country: "Nederland"} })

        // Actions
        /**
         * Create a new customer
         */
        const addCustomer = async () => {
            loading.value = true

            try {
                const { CustomerID } = await apiClient('crm/customer', {
                    method: customer.id === 0 ? 'post' : 'put',
                    body: { 'customerdata': customer }
                })
                customer.id = CustomerID
                return customer
            } catch (error) {
                return { error: error.data }
            } finally {
                loading.value = false
            }
        }

        /**
         * Update Customer information
         * @param id
         */
        const getCustomer = async (id) => {
            loading.value = true

            try {
                const { customer: data } = await apiClient('crm/customer/' + id)
                customer.value = data
                return customer
            } catch (error) {
                return { error: error.data }
            } finally {
                loading.value = false
            }
        }

        return {
            loading,
            customer,
            addCustomer,
            getCustomer
        }
    },
    {
        persist: true,
    })
