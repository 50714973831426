import { defineStore } from 'pinia'
import { apiClient } from '~/composables/apiClient'
import { useCrmStore } from '~/stores/crmStore'
import { useGlobalStore } from '~/stores/globalStore'

const initialState = []
const initialTicketState = [{ numberOfTickets: 1 }]
export const useTicketStore = defineStore('tickets', () => {
    // State
    const loading = ref(false)
    const tickets = ref(initialTicketState)
    const ticketOptions = ref(initialState)
    const preparedTickets = ref(initialState)
    const preparedExtras = ref(initialState)
    const preparedMeans = ref(initialState)
    const { priceTypeIds } = useRuntimeConfig().public
    const crmStore = useCrmStore()
    const globalStore = useGlobalStore()

    // Actions
    /**
     * Get ticket options
     * @returns {Promise<{error}>}
     */
    const getAll = async () => {
        loading.value = true

        try {
            const { pricetypes } = await apiClient('repository/pricetype')
            ticketOptions.value = pricetypes.filter((item) => {
                return priceTypeIds.includes(item.pricetypeID)
            }).map(({ name, pricetypeID }) => {
                return { name: name, pricetypeID: pricetypeID, numberOfTickets: 0 }
            })
            tickets.value = ticketOptions.value.map((item, index) => {
                if (tickets.value[index] && tickets.value[index].numberOfTickets) {
                    item.numberOfTickets = tickets.value[index].numberOfTickets
                }
                return item
            })
        } catch (error) {
            return { error: error }
        } finally {
            loading.value = false
        }
    }

    /**
     * Returns total number of tickets
     * @returns {number}
     */
    const ticketTotal = () => {
        return tickets.value.reduce((i, val) => {
            return i + parseInt(val.numberOfTickets)
        }, 0)
    }

    /**
     * Returns ticket information for a specific transaction
     * @param transactionID
     * @returns {Promise<unknown>}
     */
    const getTicket = async transactionID => {
        loading.value = true

        try {
            return await apiClient('tickets/object/' + transactionID)
        } catch (error) {
            return { error: error.data }
        } finally {
            loading.value = false
        }
    }

    /**
     * Get all file objects for given transactions
     * @param transactions
     * @returns {Promise<*[]>}
     */
    const getTicketsFromTransactions = async (transactions) => {
        let files = []
        await Promise.all(transactions.map(async (transaction) => {
            const file = await getTicket(transaction.transactionID)
            files.push(file)
        }))
        return files
    }

    /**
     * Sends a confirmation email
     * @returns {Promise<unknown>}
     */
    const sendTicketConfirmation = async (ticketFiles, transactions) => {
        loading.value = true

        let order_details = {};
        order_details.ticketFiles = ticketFiles.value
        order_details.donation = globalStore.formData.donation

        let activities = [];
        const {activityID, title, subtitle, startdate} = globalStore.formData.ticketChoice.activity
        activities.push({activityID, title, subtitle, startdate, tickets: tickets.value})
        Object.values(globalStore.formData.extra).map((extra, index) => {
            const {activityID, title, subtitle, startdate} = extra
            activities.push({activityID ,title, subtitle, startdate, tickets: tickets.value})
        })
        activities.map(activity => {
            let transaction = transactions.value.find(transaction => transaction.activityID === activity.activityID)
            activity['transactionID'] = transaction['transactionID']
        })
        order_details.activities = activities

        try {
            return await apiClient('mail/order-confirmation', {
                method: 'post',
                body: {
                    'title': 'Orderbevestiging muZIEum',
                    'customer_details': crmStore.customer,
                    'order_details': order_details
                }
            })
        } catch (error) {
            return { error: error }
        } finally {
            loading.value = false
        }
    }

    return {
        tickets, ticketOptions, preparedTickets, preparedExtras, preparedMeans, loading, getAll, ticketTotal, getTicket, getTicketsFromTransactions, sendTicketConfirmation
    }
}, {
    persist: true,
})
